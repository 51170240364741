@import "_sass-mixins/_responsive";
@import "_sass-mixins/_media";
@import "_sass-mixins/_mixins";
@import "_sass-mixins/font-face";
@import "_sass-mixins/_utilities";
@import "_sass-mixins/_variable";


.content-row {
	margin-top: 0;
	.breadcrumb-box {
		margin-top: 0;
		padding-top: 0;
		padding-bottom: 0q; } }

.z-container {
	@include m1 {
		--mlr: .5%;
		width: calc(100% - var(--mlr));
		//transition: 250ms
		margin-left: .5%;
		margin-right: .5%;
		@include rsp(--mlr, 1156px, 1445px, .5%, 1.5%);
		@include rsp(--mlr, 1446px, 1685px, 1.5%, 2.5%);
		@include rsp(margin-right margin-left, 1156px, 1445px, .5%, 1.5%);
		@include rsp(margin-right margin-left, 1446px, 1685px, 1.5%, 2.5%); } }

.z-product {
	--left-size: 495px;
	@include rsp(--left-size, 1156px, 1445px, 495px, 520px);
	@include rsp(--left-size, 1446px, 1685px, 520px, 590px);
	margin: 0;
	display: grid;
	grid-column-gap: 20px;
	@include rsp(grid-column-gap, 1446px, 1685px, 20px, 40px);
	grid-template-columns: var(--left-size) 1fr;
	//+m4
	//	grid-template-columns: var(--left-size) 1fr
	@include m5 {
		grid-template-columns: 100%; }

	@include ba {
		display: none; }
	&__left {
		#product-images {
			justify-content: flex-end;
			#main-image {
				display: flex;
				justify-content: center; } }
		.thumbnail {
			margin-bottom: 0 !important; }
		#image-additional {
			margin-bottom: 0 !important;
			@include to(1199px) {
				margin-top: 20px; }
			@include from(1200px) {
				width: 110px !important; }
			.cloud-zoom-gallery {
				@include to(1199px) {
					padding-top: 15px;
					padding-bottom: 15px; }
				@include from(1156px) {
					&:first-child {
						position: relative;

						@include b('') {
							position: absolute;
							top: -10px;
							left: 0;
							width: 110px;
							height: 110px;
							border: 2px solid darken(#eee, 20%);
							border-radius: 4px;
							z-index: 2;
							pointer-events: none;
							@include to(1199px) {
								top: 0;
								left: 50%;
								transform: translateX(-50%);
								height: 100%; } } } }
				@include m5 {
					position: relative;
					@include b('') {
						position: absolute;
						top: 0;
						left: 50%;
						width: 110px;
						height: 100%;
						border: 2px solid darken(#eee, 8%);
						border-radius: 4px;
						z-index: 2;
						pointer-events: none;
						transform: translateX(-50%); }
					&:first-child {
						@include b {
							border: 2px solid darken(#eee, 35%); } } }
				@include to(370px) {
					@include b {
						display: none; } } } } }
	&__guarantee {
		margin-top: 30px;
		padding-left: 35px;
		@include m5 {
			margin-top: 5px;
			padding-left: 15px; }
		@include m9 {
			padding-left: 0; } }

	&__right {

		.related-attr-links {
			display: grid;
			grid-gap: 14px;
			grid-template-columns: auto auto auto;

			&.is_col2 {
				grid-template-columns: 1fr 1fr;
				max-width: 88%;
				> * {
					justify-self: start; } }
			&.is_col3 {
				//grid-template-columns: 1fr 1fr 1fr
				grid-template-columns: repeat(auto-fit, minmax(176px, 1fr));
				> * {
					@include from(1286px) {
						justify-self: start; } } }


			> * {
				margin-bottom: 0; }
			// --------------
			//display: flex
			//flex-wrap: wrap

			//gap: 6px 14px
			//margin-bottom: -6px
			//margin-left: -7px
			//margin-right: -7px
			//> *
			//	margin-bottom: 6px
			//	margin-left: 7px
			//	margin-right: 7px
			&.jcsb {
 }				//justify-content: space-between
			@include m5 {
				margin-top: 30px; }
			@include m11 {
				//grid-template-columns: 100% !important
				// -------
 }				//flex-direction: column
			&__section {
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				@include m11 {
					align-items: flex-start; } } } } }

.z-guarantee {
	$b: &;
	display: flex;
	//gap: 0 10px
	align-items: baseline;
	margin-left: -5px;
	margin-right: -5px;
	> * {
		margin-left: 5px;
		margin-right: 5px; }
	@include m5 {
		grid-area: guarantee; }

	&__thumb {
		svg {} }
	&__body {
		align-self: center;
		display: flex;
		flex-direction: column;
		//gap: 4px 0
		color: #1d4446;
		> *:not(:last-child) {
			margin-bottom: 4px; } }


	&__heading {
		align-self: flex-start;
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 0.03em;
		position: relative;
		min-height: 7px;
		@include m9 {
			font-size: 16px; }
		@include b('') {
			width: 100%;
			height: 1px;
			background-color: #b0b1b7;
			position: absolute;
			top: 100%;
			left: 0; } }

	&__txt {
		font-size: 16px;
		font-weight: 600;
		@include m9 {
			font-size: 14px; } }
	a#{$b}__txt {
		&:hover {
			text-decoration: underline; } }
	&__rating-box {
		margin-top: 15px; } }

.z-rating-box {
	display: flex;
	align-items: center;
	margin-left: -5px;
	margin-right: -5px;
	> * {
		margin-left: 5px;
		margin-right: 5px; }
	&__stars {
		display: flex;
		align-items: center;
		> *:not(:last-child) {
			margin-right: 1px; }
		.fa-star {
			font-size: 18px;
			color: #cfcfcf;
			&.is_rat {
				color: rgb(255, 117, 0); } } }

	&__review-info {
		font-size: 12px;
		color: #95989a;
		letter-spacing: 0.03em;
		a {
			transition: 250ms;
			&:hover {
				color: rgb(255, 117, 0); } } } }

.z-product-header {
	@include m5 {
		display: flex;

		flex-direction: column;
		margin-bottom: 30px; }
	&__row {
		display: flex;
		flex-wrap: wrap;
		@include from(640px) {
			align-items: center; }
		justify-content: space-between;
		//gap: 10px 10px
		margin-left: -5px;
		margin-right: -5px;
		margin-bottom: -10px;
		@include m8 {
			margin-bottom: -5px; }
		> * {
			margin-left: 5px;
			margin-right: 5px;
			margin-bottom: 10px;
			@include m8 {
				margin-bottom: 5px; } } }
	&__col {
		&.is_logo {
			display: flex;
			justify-content: center;
			align-items: center;
			@include m10 {
				display: none; } } }

	&__heading {
		max-width: 100%;
		font-family: 'OpenSansRegular', sans-serif;
		font-size: 23px;
		@include rsp(font-size, 1286px, 1685px, 23px, 26px);
		line-height: 1.1;
		color: #4f5f6f;
		text-transform: uppercase;
		margin-top: 15px;
		@include rsp(margin-top, 1286px, 1685px, 15px, 10px);
		margin-bottom: 25px;
		@include rsp(margin-bottom, 1286px, 1685px, 25px, 20px);
		@include m5 {
			order: -1;
			margin-top: 0;
			margin-bottom: 10px; }
		@include m10 {
			margin-bottom: 20px; } } }


.z-product-model,
.z-product-manufacturer {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	gap: 3px 0;
	padding: 4px 10px;
	font-size: 14px;
	font-weight: 600;
	> * {
		&:not(:last-child) {
			margin-right: 5px; } }
	@include m10 {
		font-size: 12px; } }

.z-product-model {
	background-color: #f5f4f5; }

.z-product-manufacturer {
	//+m12
	//	background-color: #f5f4f5
	&__val {
		align-self: center;
		text-decoration: underline; } }

.z-product-manufacturer-logo {
	display: block;

	&__img {
		transition: 350ms ease-in-out; }
	&:hover &__img {
		transform: translateY(-4px); } }



.z-product-main {
	margin-top: 20px;
	display: grid;
	grid-template-columns: 6.6fr 3.4fr;
	grid-gap: 10px 15px;
	@include rsp(grid-column-gap, 1445px, 1875px, 15px, 35px);
	grid-template-areas: 'left right';
	@include m5 {
		grid-template-areas: 'left right' 'guarantee guarantee'; }
	@include m9 {
		grid-gap: 20px 0;
		grid-template-columns: 100%;
		grid-template-areas: 'left' 'guarantee' 'right'; }
	&__left {
		grid-area: left;
		background-color: #fbfbfb;
		border: 1px solid #ebebeb;
		padding: 14px;
		margin-top: 5px;
		margin-bottom: 5px;
		//align-self: start
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		justify-content: space-evenly; }
	&__stock {
		margin-bottom: 8px;
		font-size: 13px;
		font-weight: 600;
		text-transform: uppercase;
		color: red;
		@include m12 {
			font-size: 12px; }
		i {
			font-size: 20px;
			@include m12 {
				font-size: 18px; } } }
	&__price-panel {
		display: grid;
		grid-gap: 20px 10px;
		@include rsp(grid-column-gap, 1446px, 1685px, 10px, 30px);
		grid-template-columns: 2fr 1.9fr;
		grid-template-areas: 'price-col timer' 'button-cart button-cart';
		@include m3 {
			grid-gap: 5px 0;
			grid-template-columns: 100%;
			grid-template-areas: 'timer' 'price-col' 'button-cart'; }
		@include m5 {
			grid-gap: 5px 10px;
			grid-template-columns: 2fr 1.9fr;
			grid-template-areas: 'price-col timer' 'button-cart button-cart'; }
		@include m12 {
			grid-gap: 8px 5px; }
		@include to(370px) {
			grid-gap: 5px 0;
			grid-template-columns: 100%;
			grid-template-areas: 'price-col' 'timer'  'button-cart'; } }
	&__timer {
		grid-area: timer;
		flex-direction: column;
		position: relative;
		z-index: 1;
		line-height: 1.5;

		&#product-info-counter {
			align-items: flex-start;
			margin-bottom: 0;
			.product-info-counter-header {

				@include m5 {
					padding-top: 0;
 }					//padding-left: 15px
				@include m12 {
					font-size: 10px; } }
			.flip-clock-divider.days {
				width: 0 !important; }
			@include m12 {
				.flip-clock-divider {
					width: 4px !important; } }
			.counter {
				background-color: transparent;
				padding-left: 0;
				@include m4 {
					margin-top: 0;
					margin-bottom: 0; } }
			.flip-clock-wrapper {
				height: 48px;
				//+m6
				//	display: flex
				//	justify-content: center
				ul {
					margin-top: 0;
					margin-bottom: 0;
					li a div div.inn {
						background-color: #fbfbfb;
						font-weight: 600; } } } } }


	&__price-col {
		grid-area: price-col;
		align-self: end;
		padding-bottom: 5px;

		.product-price {
			line-height: 1.1;
			#main-product-price {
				font-size: 20px;
				color: #66878e !important;
				text-decoration-color: #CE0E5A;
				white-space: nowrap;
				@include m12 {
					font-size: 16px; }
				&:only-child {
					font-size: 36px;
					@include m12 {
						font-size: 26px; } } }
			#main-product-special {
				white-space: nowrap;
				font-size: 36px;
				margin-top: 5px;
				@include m12 {
					font-size: 26px; } } } }


	.buttons-box-line {
		margin-top: 20px;
		min-height: 0;
		justify-content: flex-start !important;
		//gap: 0 10px
		margin-left: -5px;
		margin-right: -5px;
		> * {
			margin-left: 5px;
			margin-right: 5px; }

		.oct-button-transparent {
			flex: 1 1 calc(33.33% - 10px);
			display: flex;
			align-items: center;
			gap: 3px 0;
			color: #2c606c;
			font-size: 12px;
			@include rsp(font-size, 1286px, 1445px, 12px, 14px);
			@include to(450px) {
				flex-direction: column;
				width: 33.33%;
				position: relative;
				&:not(:last-child) {
					position: relative;
					@include a('') {
						width: 1px;
						height: 100%;
						background-color: #dddddd;
						position: absolute;
						right: -.5px;
						top: 0; } } }
			> * {
				margin-left: 4px;
				margin-right: 4px; }
			svg {
				fill: currentColor;
				width: 26px;
				height: 26px;
				@include rsp(width height, 1286px, 1445px, 28px, 32px);
				margin-bottom: 3px; }
			i {
				font-size: 26px !important;
				@include rsp(font-size, 1286px, 1445px, 26px, 30px, i); }
			&__txt {
				font-weight: 600;
				line-height: 1.05;
				text-align: left;
				padding-bottom: 2px;
				@include to(450px) {
					margin-top: auto; }
				br {
					@include m1 {
						display: none; }
					@include m4 {
						display: block; }
					@include m5 {
						display: none; }
					@include m12 {
						display: block; } }
				@include m9 {
					text-align: center; } } } }



	&__button-cart {
		grid-area: button-cart;
		position: relative;
		z-index: 4;
		.oct-button {
			height: 60px;
			display: flex;
			//grid-gap: 10px
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			box-shadow: 0 3px 10px -3px rgba(0, 0, 0, .35);
			font-size: 23px;
			transition: 250ms;
			&.is_not-stock {
				background-color: grey; }
			@include m12 {
				height: 52px;
				font-size: 20px; }
			.fa {
				font-size: 20px;
				margin-right: 10px;
				@include m12 {
					font-size: 18px; } }
			&:active {
				transition: 0s;
				box-shadow: 0 2px 6px rgba(0, 0, 0, 0); } } }

	&__right {
		grid-area: right;
		@include from(741px) {
			border-left: 1px solid #ebebeb; }
		padding-left: 12px;
		@include rsp(padding-left, 1446px, 1685px, 12px, 25px); }
	&__information {
		@include m9 {
			padding-top: 10px;
			display: grid;
			grid-gap: 15px 10px;
			grid-template-columns: 1fr 1fr;
			grid-template-areas: 'info-item-1 info-item-2' 'info-item-3 info-item-3';
			@include gridItem(3, 'info-item-'); } } }



.stock {
	display: flex;
	align-items: center;
	//gap: 0 10px
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;

	@include m12 {
		font-size: 12px; }
	i {
		font-size: 20px;
		margin-right: 10px;
		@include m12 {
			font-size: 18px; } }
	&_green {
		color: #00c850; }
	&_red {
		color: #ff0000; }
	&_orange {
		color: #ff7600; } }


.z-product-info-block {
	&__heading {
		color: #2c606c;
		font-weight: 600;
		font-size: 16px;
		text-decoration: underline;
		text-decoration-color: grey;
		> * {
			color: inherit;
			text-decoration-color: inherit;
			font-size: inherit;
			font-style: inherit;
			font-weight: inherit;
			line-height: inherit;
			letter-spacing: inherit;
			margin: 0; } }
	&__listing {
		padding-left: 7px;
		li {
			font-size: 13px; } } }


.product-tabs-row {
	.nav.nav-tabs {
		display: flex;
		flex-direction: column;
		//gap: 10px 20px


		@include from(741px) {
			margin-bottom: -10px;
			margin-left: -10px;
			margin-right: -10px;
			> * {
				margin-left: 10px !important;
				margin-right: 10px !important;
				margin-bottom: 10px !important; }

			flex-direction: row;
			display: inline-flex;
			position: relative;
			//border-top: 2px solid #9e9e9e
			@include b('') {
				display: block !important;
				width: calc(100% - 20px);
				height: 2px;
				background-color: #9e9e9e;
				position: absolute;
				bottom: 100%;
				left: 10px;
				z-index: 5; } }

		@include ba {
			display: none; }
		> li {
			text-align: left !important;
			margin: 0;
			@include m9 {
				margin-bottom: 14px; }
			@include from(741px) {
				color: rgb(38, 73, 85);
				position: relative;
				@include b('') {
					width: 100%;
					height: 4px;
					background-color: #ff7602;
					position: absolute;
					bottom: 100%;
					left: 0;
					transform: translate(0, 1px) scale(0, 1);
					transition: 300ms;
					z-index: 6; }

				&.active {
					@include b('') {
						transform: translate(0, 1px) scale(1,1); } } }


			.nav-tabs__link {
				margin: 0;
				border-radius: 0;
				padding: 5px 10px;
				font-size: 24px;
				font-weight: 500;
				letter-spacing: 0.02em;
				font-family: 'Open Sans', sans-serif !important;
				@include m9 {
					font-size: 18px;
					.tab-icon {
						display: block;
						width: 28px;
						height: 28px;
						border-radius: 50%;
						position: absolute;
						rightrelated-attr-links : 15px;
						top: 50%;
						right: 10px;
						transform: translateY(-50%);
						background-color: #fff;
						@include ba('') {
							background-color: rgb(38, 73, 85);
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%); }
						@include b {
							width: 60%;
							height: 2px; }

						@include a {
							width: 2px;
							height: 60%; } } } }

			.nav-tabs__link,
			&:hover .nav-tabs__link,
			&.active .nav-tabs__link {
				background-color: transparent;
				color: inherit;
				@include m9 {
					border: 1px solid #cfcdcd;
					background-color: #efefef;
					border-radius: 5px;
					color: #2c606c;
					min-height: 50px;
					display: flex;
					align-items: center;
					padding-right: 50px; }
				@include ba {
					display: none; } }
			&.active .nav-tabs__link {
				@include m9 {
					background-color: #fe7600;
					border-color: #fe7600;
					color: #fff;
					border-radius: 5px 5px 0 0;
					.tab-icon {
						@include a {
							transform: translate(-50%, -50%) scale(1, 0); } } } } } }

	.tab-pane {
		@include m9 {
			border: 1px solid #cfcdcd;
			//background-color: #efefef
			background-color: #fff;
			padding: 15px;
			border-radius: 0 0 5px 5px; } } }

.z-heading {
	font-weight: 600;
	margin-left: 35px + 40 + 10;

	margin-top: 45px;
	@include rsp(margin-top, 1286px, 1685px, 45px, 35px);
	margin-bottom: 40px;
	@include rsp(margin-bottom, 1286px, 1685px, 40px, 30px);

	font-size: 25px;
	letter-spacing: .01em;
	@include m5 {
		margin-left: 63px; }
	@include m9 {
		margin-left: 0;
		margin-top: 10px;
		font-size: 20px;
		margin-bottom: 20px; } }
#autoreleted {
	@include from(866px) {
		padding-left: 14px;
		padding-right: 14px; }
	@include m9 {
		border: 2px solid #666;
		border-radius: 5px;
		overflow: hidden;
		padding-left: 20px;
		padding-right: 20px;
		margin-top: 10px; }

	.owl-item {
		@include from(866px) {
			&:not(:first-child) .item {
				margin-left: 14px; }
			&:not(:last-child)  .item {
				margin-right: 14px; } }
		@include m8 {
			padding-left: 10px;
			padding-right: 10px;
			//margin-left: 15px
 } }			//margin-right: 15px

	.item {
		padding-bottom: 10px;
		.image {
			min-height: 150px; }

		.oct-related-picture {
			display: block;

			img {
				width: 100%;
				max-width: 150px; } }


		.name {
			margin-top: 20px;
			padding-bottom: 2px;
			border-bottom: 1px solid #ddd;
			text-align: left;
			a {
				transition: 250ms;
				height: auto !important;
				min-height: 56px;
				display: flex;
				align-items: center;
				text-align: left;
				line-height: 1.5;
				//letter-spacing: 0.03em

				@include m8 {
					text-align: center;
					justify-content: center; } } }

		.price {
			margin-top: 10px;
			text-align: left;
			letter-spacing: .03em;
			transition: 240ms, transform 500ms;
			@include m8 {
				text-align: center;
				margin-bottom: 0; } }


		.cart {
			display: flex;
			justify-content: center;
			//gap: 0 4px
			margin-left: -2px;
			margin-right: -2px;
			> * {
				margin-left: 2px;
				margin-right: 2px;
				padding: 0;
				height: 36px;
				line-height: 1.1;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				//gap: 0 3px

				@include from(866px) {
					opacity: 0;
					transform: translateY(100%); }

				&:first-child {
					flex-grow: .5;
					//max-width: 86px
					padding-left: 8px !important;
					padding-right: 8px !important;
					font-size: 12px;
					box-shadow: 0 0px 8px -1px rgba(0, 0, 0, 0.15);
					i {
						margin-right: 3px; }
					&.out-of-stock-button {
						cursor: text !important;
						background-color: grey; } }

				&:not(:first-child) {
					width: 36px;
					background-color: transparent;
					color: #4f5f6f !important;
					border: 2px solid #ddd;
					transition: 200ms, transform 300ms, opacity 300ms, box-shadow 50ms;
					border-radius: 4px;
					&:hover {
						background-color: #4f5f6f !important;
						color: #fff !important;
						border-color: #4f5f6f; }
					i {
						font-size: 18px; } }

				&:nth-child(1) {
					transition: 200ms, transform 500ms, opacity 300ms, box-shadow 300ms 400ms; }
				&:nth-child(2) {
					transition: 200ms, transform 450ms 50ms, opacity 250ms 50ms; }
				&:nth-child(3) {
					transition: 200ms, transform 450ms 100ms, opacity 250ms 100ms; } } }

		&:hover {
			@include from(866px) {
				.price {
					opacity: 0;
					pointer-events: none;

					transform: translateY(-100%); }
				.cart {
					> * {
						transform: translateY(-100%);
						opacity: 1;
						&:first-child {
							box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3); } } } } } }

	.owl-prev,
	.owl-next {
		position: absolute;
		top: 60px;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center; }

	.owl-prev {
		left: auto;
		right: 100%;
		@include m1 {
			transform: translateX(100%); }
		@include m9 {
			transform: translateX(100%) translateX(-20px); } }

	.owl-next {
		left: 100%;
		right: auto;
		@include m1 {
			transform: translateX(-100%); }
		@include m9 {
			transform: translateX(-100%) translateX(20px); } } }


.item.item-box,
.product-thumb .cat-box-effect-inner {
	.cart {
		display: flex;
		justify-content: center;
		//gap: 0 4px
		//margin-left: -2px
		//margin-right: -2px
		> * {
			margin-left: 2px;
			margin-right: 2px;
			padding: 0 !important;
			height: 36px;
			line-height: 1.1;
			display: flex !important;
			align-items: center;
			justify-content: center;
			text-align: center;
			min-width: 36px;
			&:first-child {
				flex-grow: .5;
				//max-width: 86px
				padding-left: 8px !important;
				padding-right: 8px !important;
				font-size: 12px;
				box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.15);
				i {
					margin-left: 3px;
					margin-right: 3px; }
				&.out-of-stock-button {
					cursor: text !important;
					background-color: grey !important; } }

			&:not(:first-child) {
				width: 36px;
				background-color: transparent !important;
				color: #264955 !important;
				border: 2px solid #ddd;
				transition: 200ms, transform 300ms, opacity 300ms, box-shadow 50ms;
				border-radius: 4px;
				&:hover {
					background-color: #264955 !important;
					color: #fff !important;
					border-color: #264955 !important; }
				i {
					font-size: 18px; } } }
		.wishlist {
			i {
				@include b('\f08a'); } } } }


@include from(993px) {
	.is_flex {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include ba {
			display: none; } } }
