// max-width points
$m1: 1685px;
$m2: 1445px;
$m3: 1370px;
$m4: 1285px;
$m5: 1155px;
$m6: 1055px;
$m7: 965px;
$m8: 865px;
$m9: 740px;
$m10: 639px;
$m11: 570px;
$m12: 480px;
$m13: 359px;
$m14: 320px;


// min-width points
$mm1: 280px;
$mm2: 370px;
$mm3: 409px;
$mm4: 563px;
$mm5: 662px;
$mm6: 731px;
$mm7: 763px;
$mm8: 805px;
$mm9: 912px;
$mm10: 1019px;
$mm11: 1107px;
$mm12: 1280px;
$mm13: 1361px;
$mm14: 1435px;
$mm15: 1675px;
$mm16: 1915px;


// media query
// ==========================================================================

// custom points
@mixin from($min_width) {
  @media screen and (min-width: $min_width) {
    @content; } }

@mixin to($max_width) {
  @media screen and (max-width: $max_width) {
    @content; } }

@mixin from-to($min_width, $max_width) {
  @media screen and (min-width: $min_width) and (max-width: $max_width) {
    @content; } }


// points - max-width
@mixin m1() {
  @media screen and (max-width: $m1) {
    @content; } }
@mixin m2() {
  @media screen and (max-width: $m2) {
    @content; } }
@mixin m3() {
  @media screen and (max-width: $m3) {
    @content; } }
@mixin m4() {
  @media screen and (max-width: $m4) {
    @content; } }
@mixin m5() {
  @media screen and (max-width: $m5) {
    @content; } }
@mixin m6() {
  @media screen and (max-width: $m6) {
    @content; } }
@mixin m7() {
  @media screen and (max-width: $m7) {
    @content; } }
@mixin m8() {
  @media screen and (max-width: $m8) {
    @content; } }
@mixin m9() {
  @media screen and (max-width: $m9) {
    @content; } }
@mixin m10() {
  @media screen and (max-width: $m10) {
    @content; } }
@mixin m11() {
  @media screen and (max-width: $m11) {
    @content; } }
@mixin m12() {
  @media screen and (max-width: $m12) {
    @content; } }
@mixin m13() {
  @media screen and (max-width: $m13) {
    @content; } }
@mixin m14() {
  @media screen and (max-width: $m14) {
    @content; } }


// points - min-width
@mixin mm1() {
  @media screen and (min-width: $mm1) {
    @content; } }
@mixin mm2() {
  @media screen and (min-width: $mm2) {
    @content; } }
@mixin mm3() {
  @media screen and (min-width: $mm3) {
    @content; } }
@mixin mm4() {
  @media screen and (min-width: $mm4) {
    @content; } }
@mixin mm5() {
  @media screen and (min-width: $mm5) {
    @content; } }
@mixin mm6() {
  @media screen and (min-width: $mm6) {
    @content; } }
@mixin mm7() {
  @media screen and (min-width: $mm7) {
    @content; } }
@mixin mm8() {
  @media screen and (min-width: $mm8) {
    @content; } }
@mixin mm9() {
  @media screen and (min-width: $mm9) {
    @content; } }
@mixin mm10() {
  @media screen and (min-width: $mm10) {
    @content; } }
@mixin mm11() {
  @media screen and (min-width: $mm11) {
    @content; } }
@mixin mm12() {
  @media screen and (min-width: $mm12) {
    @content; } }
@mixin mm13() {
  @media screen and (min-width: $mm13) {
    @content; } }
@mixin mm14() {
  @media screen and (min-width: $mm14) {
    @content; } }
@mixin mm15() {
  @media screen and (min-width: $mm15) {
    @content; } }
@mixin mm16() {
  @media screen and (min-width: $mm16) {
    @content; } }


