@charset "UTF-8";
.content-row {
  margin-top: 0;
}

.content-row .breadcrumb-box {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0q;
}

@media screen and (max-width: 1685px) {
  .z-container {
    --mlr: .5%;
    width: calc(100% - var(--mlr));
    margin-left: .5%;
    margin-right: .5%;
  }
}

@media screen and (max-width: 1685px) and (min-width: 1156px) {
  .z-container {
    --mlr: calc(0.5% + 1 * (100vw - 1156px) / 289) ;
  }
}

@media screen and (max-width: 1685px) and (min-width: 1445px) {
  .z-container {
    --mlr: 1.5% ;
  }
}

@media screen and (max-width: 1685px) and (min-width: 1446px) {
  .z-container {
    --mlr: calc(1.5% + 1 * (100vw - 1446px) / 239) ;
  }
}

@media screen and (max-width: 1685px) and (min-width: 1685px) {
  .z-container {
    --mlr: 2.5% ;
  }
}

@media screen and (max-width: 1685px) and (min-width: 1156px) {
  .z-container {
    margin-right: calc(0.5% + 1 * (100vw - 1156px) / 289) ;
    margin-left: calc(0.5% + 1 * (100vw - 1156px) / 289) ;
  }
}

@media screen and (max-width: 1685px) and (min-width: 1445px) {
  .z-container {
    margin-right: 1.5% ;
    margin-left: 1.5% ;
  }
}

@media screen and (max-width: 1685px) and (min-width: 1446px) {
  .z-container {
    margin-right: calc(1.5% + 1 * (100vw - 1446px) / 239) ;
    margin-left: calc(1.5% + 1 * (100vw - 1446px) / 239) ;
  }
}

@media screen and (max-width: 1685px) and (min-width: 1685px) {
  .z-container {
    margin-right: 2.5% ;
    margin-left: 2.5% ;
  }
}

.z-product {
  --left-size: 495px;
  margin: 0;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: var(--left-size) 1fr;
}

@media screen and (min-width: 1156px) {
  .z-product {
    --left-size: calc(495px + 25 * (100vw - 1156px) / 289) ;
  }
}

@media screen and (min-width: 1445px) {
  .z-product {
    --left-size: 520px ;
  }
}

@media screen and (min-width: 1446px) {
  .z-product {
    --left-size: calc(520px + 70 * (100vw - 1446px) / 239) ;
  }
}

@media screen and (min-width: 1685px) {
  .z-product {
    --left-size: 590px ;
  }
}

@media screen and (min-width: 1446px) {
  .z-product {
    grid-column-gap: calc(20px + 20 * (100vw - 1446px) / 239) ;
  }
}

@media screen and (min-width: 1685px) {
  .z-product {
    grid-column-gap: 40px ;
  }
}

@media screen and (max-width: 1155px) {
  .z-product {
    grid-template-columns: 100%;
  }
}

.z-product::before, .z-product::after {
  display: none;
}

.z-product__left #product-images {
  justify-content: flex-end;
}

.z-product__left #product-images #main-image {
  display: flex;
  justify-content: center;
}

.z-product__left .thumbnail {
  margin-bottom: 0 !important;
}

.z-product__left #image-additional {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 1199px) {
  .z-product__left #image-additional {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .z-product__left #image-additional {
    width: 110px !important;
  }
}

@media screen and (max-width: 1199px) {
  .z-product__left #image-additional .cloud-zoom-gallery {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 1156px) {
  .z-product__left #image-additional .cloud-zoom-gallery:first-child {
    position: relative;
  }
  .z-product__left #image-additional .cloud-zoom-gallery:first-child::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 110px;
    height: 110px;
    border: 2px solid #bbbbbb;
    border-radius: 4px;
    z-index: 2;
    pointer-events: none;
  }
}

@media screen and (min-width: 1156px) and (max-width: 1199px) {
  .z-product__left #image-additional .cloud-zoom-gallery:first-child::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
}

@media screen and (max-width: 1155px) {
  .z-product__left #image-additional .cloud-zoom-gallery {
    position: relative;
  }
  .z-product__left #image-additional .cloud-zoom-gallery::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 110px;
    height: 100%;
    border: 2px solid #dadada;
    border-radius: 4px;
    z-index: 2;
    pointer-events: none;
    transform: translateX(-50%);
  }
  .z-product__left #image-additional .cloud-zoom-gallery:first-child::before {
    border: 2px solid #959595;
  }
}

@media screen and (max-width: 370px) {
  .z-product__left #image-additional .cloud-zoom-gallery::before {
    display: none;
  }
}

.z-product__guarantee {
  margin-top: 30px;
  padding-left: 35px;
}

@media screen and (max-width: 1155px) {
  .z-product__guarantee {
    margin-top: 5px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 740px) {
  .z-product__guarantee {
    padding-left: 0;
  }
}

.z-product__right .related-attr-links {
  display: grid;
  grid-gap: 14px;
  grid-template-columns: auto auto auto;
}

.z-product__right .related-attr-links.is_col2 {
  grid-template-columns: 1fr 1fr;
  max-width: 88%;
}

.z-product__right .related-attr-links.is_col2 > * {
  justify-self: start;
}

.z-product__right .related-attr-links.is_col3 {
  grid-template-columns: repeat(auto-fit, minmax(176px, 1fr));
}

@media screen and (min-width: 1286px) {
  .z-product__right .related-attr-links.is_col3 > * {
    justify-self: start;
  }
}

.z-product__right .related-attr-links > * {
  margin-bottom: 0;
}

@media screen and (max-width: 1155px) {
  .z-product__right .related-attr-links {
    margin-top: 30px;
  }
}

.z-product__right .related-attr-links__section {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 570px) {
  .z-product__right .related-attr-links__section {
    align-items: flex-start;
  }
}

.z-guarantee {
  display: flex;
  align-items: baseline;
  margin-left: -5px;
  margin-right: -5px;
}

.z-guarantee > * {
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 1155px) {
  .z-guarantee {
    grid-area: guarantee;
  }
}

.z-guarantee__body {
  align-self: center;
  display: flex;
  flex-direction: column;
  color: #1d4446;
}

.z-guarantee__body > *:not(:last-child) {
  margin-bottom: 4px;
}

.z-guarantee__heading {
  align-self: flex-start;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03em;
  position: relative;
  min-height: 7px;
}

@media screen and (max-width: 740px) {
  .z-guarantee__heading {
    font-size: 16px;
  }
}

.z-guarantee__heading::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #b0b1b7;
  position: absolute;
  top: 100%;
  left: 0;
}

.z-guarantee__txt {
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: 740px) {
  .z-guarantee__txt {
    font-size: 14px;
  }
}

.z-guarantee a.z-guarantee__txt:hover {
  text-decoration: underline;
}

.z-guarantee__rating-box {
  margin-top: 15px;
}

.z-rating-box {
  display: flex;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
}

.z-rating-box > * {
  margin-left: 5px;
  margin-right: 5px;
}

.z-rating-box__stars {
  display: flex;
  align-items: center;
}

.z-rating-box__stars > *:not(:last-child) {
  margin-right: 1px;
}

.z-rating-box__stars .fa-star {
  font-size: 18px;
  color: #cfcfcf;
}

.z-rating-box__stars .fa-star.is_rat {
  color: #ff7500;
}

.z-rating-box__review-info {
  font-size: 12px;
  color: #95989a;
  letter-spacing: 0.03em;
}

.z-rating-box__review-info a {
  transition: 250ms;
}

.z-rating-box__review-info a:hover {
  color: #ff7500;
}

@media screen and (max-width: 1155px) {
  .z-product-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.z-product-header__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px;
}

@media screen and (min-width: 640px) {
  .z-product-header__row {
    align-items: center;
  }
}

@media screen and (max-width: 865px) {
  .z-product-header__row {
    margin-bottom: -5px;
  }
}

.z-product-header__row > * {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

@media screen and (max-width: 865px) {
  .z-product-header__row > * {
    margin-bottom: 5px;
  }
}

.z-product-header__col.is_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 639px) {
  .z-product-header__col.is_logo {
    display: none;
  }
}

.z-product-header__heading {
  max-width: 100%;
  font-family: 'OpenSansRegular', sans-serif;
  font-size: 23px;
  line-height: 1.1;
  color: #4f5f6f;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 25px;
}

@media screen and (min-width: 1286px) {
  .z-product-header__heading {
    font-size: calc(23px + 3 * (100vw - 1286px) / 399) ;
  }
}

@media screen and (min-width: 1685px) {
  .z-product-header__heading {
    font-size: 26px ;
  }
}

@media screen and (min-width: 1286px) {
  .z-product-header__heading {
    margin-top: calc(15px + -5 * (100vw - 1286px) / 399) ;
  }
}

@media screen and (min-width: 1685px) {
  .z-product-header__heading {
    margin-top: 10px ;
  }
}

@media screen and (min-width: 1286px) {
  .z-product-header__heading {
    margin-bottom: calc(25px + -5 * (100vw - 1286px) / 399) ;
  }
}

@media screen and (min-width: 1685px) {
  .z-product-header__heading {
    margin-bottom: 20px ;
  }
}

@media screen and (max-width: 1155px) {
  .z-product-header__heading {
    order: -1;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 639px) {
  .z-product-header__heading {
    margin-bottom: 20px;
  }
}

.z-product-model,
.z-product-manufacturer {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 3px 0;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 600;
}

.z-product-model > *:not(:last-child),
.z-product-manufacturer > *:not(:last-child) {
  margin-right: 5px;
}

@media screen and (max-width: 639px) {
  .z-product-model,
  .z-product-manufacturer {
    font-size: 12px;
  }
}

.z-product-model {
  background-color: #f5f4f5;
}

.z-product-manufacturer__val {
  align-self: center;
  text-decoration: underline;
}

.z-product-manufacturer-logo {
  display: block;
}

.z-product-manufacturer-logo__img {
  transition: 350ms ease-in-out;
}

.z-product-manufacturer-logo:hover .z-product-manufacturer-logo__img {
  transform: translateY(-4px);
}

.z-product-main {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 6.6fr 3.4fr;
  grid-gap: 10px 15px;
  grid-template-areas: 'left right';
}

@media screen and (min-width: 1445px) {
  .z-product-main {
    grid-column-gap: calc(15px + 20 * (100vw - 1445px) / 430) ;
  }
}

@media screen and (min-width: 1875px) {
  .z-product-main {
    grid-column-gap: 35px ;
  }
}

@media screen and (max-width: 1155px) {
  .z-product-main {
    grid-template-areas: 'left right' 'guarantee guarantee';
  }
}

@media screen and (max-width: 740px) {
  .z-product-main {
    grid-gap: 20px 0;
    grid-template-columns: 100%;
    grid-template-areas: 'left' 'guarantee' 'right';
  }
}

.z-product-main__left {
  grid-area: left;
  background-color: #fbfbfb;
  border: 1px solid #ebebeb;
  padding: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-content: space-evenly;
}

.z-product-main__stock {
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: red;
}

@media screen and (max-width: 480px) {
  .z-product-main__stock {
    font-size: 12px;
  }
}

.z-product-main__stock i {
  font-size: 20px;
}

@media screen and (max-width: 480px) {
  .z-product-main__stock i {
    font-size: 18px;
  }
}

.z-product-main__price-panel {
  display: grid;
  grid-gap: 20px 10px;
  grid-template-columns: 2fr 1.9fr;
  grid-template-areas: 'price-col timer' 'button-cart button-cart';
}

@media screen and (min-width: 1446px) {
  .z-product-main__price-panel {
    grid-column-gap: calc(10px + 20 * (100vw - 1446px) / 239) ;
  }
}

@media screen and (min-width: 1685px) {
  .z-product-main__price-panel {
    grid-column-gap: 30px ;
  }
}

@media screen and (max-width: 1370px) {
  .z-product-main__price-panel {
    grid-gap: 5px 0;
    grid-template-columns: 100%;
    grid-template-areas: 'timer' 'price-col' 'button-cart';
  }
}

@media screen and (max-width: 1155px) {
  .z-product-main__price-panel {
    grid-gap: 5px 10px;
    grid-template-columns: 2fr 1.9fr;
    grid-template-areas: 'price-col timer' 'button-cart button-cart';
  }
}

@media screen and (max-width: 480px) {
  .z-product-main__price-panel {
    grid-gap: 8px 5px;
  }
}

@media screen and (max-width: 370px) {
  .z-product-main__price-panel {
    grid-gap: 5px 0;
    grid-template-columns: 100%;
    grid-template-areas: 'price-col' 'timer'  'button-cart';
  }
}

.z-product-main__timer {
  grid-area: timer;
  flex-direction: column;
  position: relative;
  z-index: 1;
  line-height: 1.5;
}

.z-product-main__timer#product-info-counter {
  align-items: flex-start;
  margin-bottom: 0;
}

@media screen and (max-width: 1155px) {
  .z-product-main__timer#product-info-counter .product-info-counter-header {
    padding-top: 0;
  }
}

@media screen and (max-width: 480px) {
  .z-product-main__timer#product-info-counter .product-info-counter-header {
    font-size: 10px;
  }
}

.z-product-main__timer#product-info-counter .flip-clock-divider.days {
  width: 0 !important;
}

@media screen and (max-width: 480px) {
  .z-product-main__timer#product-info-counter .flip-clock-divider {
    width: 4px !important;
  }
}

.z-product-main__timer#product-info-counter .counter {
  background-color: transparent;
  padding-left: 0;
}

@media screen and (max-width: 1285px) {
  .z-product-main__timer#product-info-counter .counter {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.z-product-main__timer#product-info-counter .flip-clock-wrapper {
  height: 48px;
}

.z-product-main__timer#product-info-counter .flip-clock-wrapper ul {
  margin-top: 0;
  margin-bottom: 0;
}

.z-product-main__timer#product-info-counter .flip-clock-wrapper ul li a div div.inn {
  background-color: #fbfbfb;
  font-weight: 600;
}

.z-product-main__price-col {
  grid-area: price-col;
  align-self: end;
  padding-bottom: 5px;
}

.z-product-main__price-col .product-price {
  line-height: 1.1;
}

.z-product-main__price-col .product-price #main-product-price {
  font-size: 20px;
  color: #66878e !important;
  text-decoration-color: #CE0E5A;
  white-space: nowrap;
}

@media screen and (max-width: 480px) {
  .z-product-main__price-col .product-price #main-product-price {
    font-size: 16px;
  }
}

.z-product-main__price-col .product-price #main-product-price:only-child {
  font-size: 36px;
}

@media screen and (max-width: 480px) {
  .z-product-main__price-col .product-price #main-product-price:only-child {
    font-size: 26px;
  }
}

.z-product-main__price-col .product-price #main-product-special {
  white-space: nowrap;
  font-size: 36px;
  margin-top: 5px;
}

@media screen and (max-width: 480px) {
  .z-product-main__price-col .product-price #main-product-special {
    font-size: 26px;
  }
}

.z-product-main .buttons-box-line {
  margin-top: 20px;
  min-height: 0;
  justify-content: flex-start !important;
  margin-left: -5px;
  margin-right: -5px;
}

.z-product-main .buttons-box-line > * {
  margin-left: 5px;
  margin-right: 5px;
}

.z-product-main .buttons-box-line .oct-button-transparent {
  flex: 1 1 calc(33.33% - 10px);
  display: flex;
  align-items: center;
  gap: 3px 0;
  color: #2c606c;
  font-size: 12px;
}

@media screen and (min-width: 1286px) {
  .z-product-main .buttons-box-line .oct-button-transparent {
    font-size: calc(12px + 2 * (100vw - 1286px) / 159) ;
  }
}

@media screen and (min-width: 1445px) {
  .z-product-main .buttons-box-line .oct-button-transparent {
    font-size: 14px ;
  }
}

@media screen and (max-width: 450px) {
  .z-product-main .buttons-box-line .oct-button-transparent {
    flex-direction: column;
    width: 33.33%;
    position: relative;
  }
  .z-product-main .buttons-box-line .oct-button-transparent:not(:last-child) {
    position: relative;
  }
  .z-product-main .buttons-box-line .oct-button-transparent:not(:last-child)::after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #dddddd;
    position: absolute;
    right: -.5px;
    top: 0;
  }
}

.z-product-main .buttons-box-line .oct-button-transparent > * {
  margin-left: 4px;
  margin-right: 4px;
}

.z-product-main .buttons-box-line .oct-button-transparent svg {
  fill: currentColor;
  width: 26px;
  height: 26px;
  margin-bottom: 3px;
}

@media screen and (min-width: 1286px) {
  .z-product-main .buttons-box-line .oct-button-transparent svg {
    width: calc(28px + 4 * (100vw - 1286px) / 159) ;
    height: calc(28px + 4 * (100vw - 1286px) / 159) ;
  }
}

@media screen and (min-width: 1445px) {
  .z-product-main .buttons-box-line .oct-button-transparent svg {
    width: 32px ;
    height: 32px ;
  }
}

.z-product-main .buttons-box-line .oct-button-transparent i {
  font-size: 26px !important;
}

@media screen and (min-width: 1286px) {
  .z-product-main .buttons-box-line .oct-button-transparent i {
    font-size: calc(26px + 4 * (100vw - 1286px) / 159) !important;
  }
}

@media screen and (min-width: 1445px) {
  .z-product-main .buttons-box-line .oct-button-transparent i {
    font-size: 30px !important;
  }
}

.z-product-main .buttons-box-line .oct-button-transparent__txt {
  font-weight: 600;
  line-height: 1.05;
  text-align: left;
  padding-bottom: 2px;
}

@media screen and (max-width: 450px) {
  .z-product-main .buttons-box-line .oct-button-transparent__txt {
    margin-top: auto;
  }
}

@media screen and (max-width: 1685px) {
  .z-product-main .buttons-box-line .oct-button-transparent__txt br {
    display: none;
  }
}

@media screen and (max-width: 1285px) {
  .z-product-main .buttons-box-line .oct-button-transparent__txt br {
    display: block;
  }
}

@media screen and (max-width: 1155px) {
  .z-product-main .buttons-box-line .oct-button-transparent__txt br {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .z-product-main .buttons-box-line .oct-button-transparent__txt br {
    display: block;
  }
}

@media screen and (max-width: 740px) {
  .z-product-main .buttons-box-line .oct-button-transparent__txt {
    text-align: center;
  }
}

.z-product-main__button-cart {
  grid-area: button-cart;
  position: relative;
  z-index: 4;
}

.z-product-main__button-cart .oct-button {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.35);
  font-size: 23px;
  transition: 250ms;
}

.z-product-main__button-cart .oct-button.is_not-stock {
  background-color: grey;
}

@media screen and (max-width: 480px) {
  .z-product-main__button-cart .oct-button {
    height: 52px;
    font-size: 20px;
  }
}

.z-product-main__button-cart .oct-button .fa {
  font-size: 20px;
  margin-right: 10px;
}

@media screen and (max-width: 480px) {
  .z-product-main__button-cart .oct-button .fa {
    font-size: 18px;
  }
}

.z-product-main__button-cart .oct-button:active {
  transition: 0s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0);
}

.z-product-main__right {
  grid-area: right;
  padding-left: 12px;
}

@media screen and (min-width: 741px) {
  .z-product-main__right {
    border-left: 1px solid #ebebeb;
  }
}

@media screen and (min-width: 1446px) {
  .z-product-main__right {
    padding-left: calc(12px + 13 * (100vw - 1446px) / 239) ;
  }
}

@media screen and (min-width: 1685px) {
  .z-product-main__right {
    padding-left: 25px ;
  }
}

@media screen and (max-width: 740px) {
  .z-product-main__information {
    padding-top: 10px;
    display: grid;
    grid-gap: 15px 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'info-item-1 info-item-2' 'info-item-3 info-item-3';
  }
  .z-product-main__information > *:nth-child(1) {
    grid-area: info-item-1;
  }
  .z-product-main__information > *:nth-child(2) {
    grid-area: info-item-2;
  }
  .z-product-main__information > *:nth-child(3) {
    grid-area: info-item-3;
  }
}

.stock {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

@media screen and (max-width: 480px) {
  .stock {
    font-size: 12px;
  }
}

.stock i {
  font-size: 20px;
  margin-right: 10px;
}

@media screen and (max-width: 480px) {
  .stock i {
    font-size: 18px;
  }
}

.stock_green {
  color: #00c850;
}

.stock_red {
  color: #ff0000;
}

.stock_orange {
  color: #ff7600;
}

.z-product-info-block__heading {
  color: #2c606c;
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-color: grey;
}

.z-product-info-block__heading > * {
  color: inherit;
  text-decoration-color: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  margin: 0;
}

.z-product-info-block__listing {
  padding-left: 7px;
}

.z-product-info-block__listing li {
  font-size: 13px;
}

.product-tabs-row .nav.nav-tabs {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 741px) {
  .product-tabs-row .nav.nav-tabs {
    margin-bottom: -10px;
    margin-left: -10px;
    margin-right: -10px;
    flex-direction: row;
    display: inline-flex;
    position: relative;
  }
  .product-tabs-row .nav.nav-tabs > * {
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
  .product-tabs-row .nav.nav-tabs::before {
    content: "";
    display: block !important;
    width: calc(100% - 20px);
    height: 2px;
    background-color: #9e9e9e;
    position: absolute;
    bottom: 100%;
    left: 10px;
    z-index: 5;
  }
}

.product-tabs-row .nav.nav-tabs::before, .product-tabs-row .nav.nav-tabs::after {
  display: none;
}

.product-tabs-row .nav.nav-tabs > li {
  text-align: left !important;
  margin: 0;
}

@media screen and (max-width: 740px) {
  .product-tabs-row .nav.nav-tabs > li {
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 741px) {
  .product-tabs-row .nav.nav-tabs > li {
    color: #264955;
    position: relative;
  }
  .product-tabs-row .nav.nav-tabs > li::before {
    content: "";
    width: 100%;
    height: 4px;
    background-color: #ff7602;
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: translate(0, 1px) scale(0, 1);
    transition: 300ms;
    z-index: 6;
  }
  .product-tabs-row .nav.nav-tabs > li.active::before {
    content: "";
    transform: translate(0, 1px) scale(1, 1);
  }
}

.product-tabs-row .nav.nav-tabs > li .nav-tabs__link {
  margin: 0;
  border-radius: 0;
  padding: 5px 10px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.02em;
  font-family: 'Open Sans', sans-serif !important;
}

@media screen and (max-width: 740px) {
  .product-tabs-row .nav.nav-tabs > li .nav-tabs__link {
    font-size: 18px;
  }
  .product-tabs-row .nav.nav-tabs > li .nav-tabs__link .tab-icon {
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    rightrelated-attr-links: 15px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: #fff;
  }
  .product-tabs-row .nav.nav-tabs > li .nav-tabs__link .tab-icon::before, .product-tabs-row .nav.nav-tabs > li .nav-tabs__link .tab-icon::after {
    content: "";
    background-color: #264955;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .product-tabs-row .nav.nav-tabs > li .nav-tabs__link .tab-icon::before {
    width: 60%;
    height: 2px;
  }
  .product-tabs-row .nav.nav-tabs > li .nav-tabs__link .tab-icon::after {
    width: 2px;
    height: 60%;
  }
}

.product-tabs-row .nav.nav-tabs > li .nav-tabs__link,
.product-tabs-row .nav.nav-tabs > li:hover .nav-tabs__link,
.product-tabs-row .nav.nav-tabs > li.active .nav-tabs__link {
  background-color: transparent;
  color: inherit;
}

@media screen and (max-width: 740px) {
  .product-tabs-row .nav.nav-tabs > li .nav-tabs__link,
  .product-tabs-row .nav.nav-tabs > li:hover .nav-tabs__link,
  .product-tabs-row .nav.nav-tabs > li.active .nav-tabs__link {
    border: 1px solid #cfcdcd;
    background-color: #efefef;
    border-radius: 5px;
    color: #2c606c;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding-right: 50px;
  }
}

.product-tabs-row .nav.nav-tabs > li .nav-tabs__link::before, .product-tabs-row .nav.nav-tabs > li .nav-tabs__link::after,
.product-tabs-row .nav.nav-tabs > li:hover .nav-tabs__link::before,
.product-tabs-row .nav.nav-tabs > li:hover .nav-tabs__link::after,
.product-tabs-row .nav.nav-tabs > li.active .nav-tabs__link::before,
.product-tabs-row .nav.nav-tabs > li.active .nav-tabs__link::after {
  display: none;
}

@media screen and (max-width: 740px) {
  .product-tabs-row .nav.nav-tabs > li.active .nav-tabs__link {
    background-color: #fe7600;
    border-color: #fe7600;
    color: #fff;
    border-radius: 5px 5px 0 0;
  }
  .product-tabs-row .nav.nav-tabs > li.active .nav-tabs__link .tab-icon::after {
    transform: translate(-50%, -50%) scale(1, 0);
  }
}

@media screen and (max-width: 740px) {
  .product-tabs-row .tab-pane {
    border: 1px solid #cfcdcd;
    background-color: #fff;
    padding: 15px;
    border-radius: 0 0 5px 5px;
  }
}

.z-heading {
  font-weight: 600;
  margin-left: 85px;
  margin-top: 45px;
  margin-bottom: 40px;
  font-size: 25px;
  letter-spacing: .01em;
}

@media screen and (min-width: 1286px) {
  .z-heading {
    margin-top: calc(45px + -10 * (100vw - 1286px) / 399) ;
  }
}

@media screen and (min-width: 1685px) {
  .z-heading {
    margin-top: 35px ;
  }
}

@media screen and (min-width: 1286px) {
  .z-heading {
    margin-bottom: calc(40px + -10 * (100vw - 1286px) / 399) ;
  }
}

@media screen and (min-width: 1685px) {
  .z-heading {
    margin-bottom: 30px ;
  }
}

@media screen and (max-width: 1155px) {
  .z-heading {
    margin-left: 63px;
  }
}

@media screen and (max-width: 740px) {
  .z-heading {
    margin-left: 0;
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 866px) {
  #autoreleted {
    padding-left: 14px;
    padding-right: 14px;
  }
}

@media screen and (max-width: 740px) {
  #autoreleted {
    border-radius: 5px;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }
}

@media screen and (min-width: 866px) {
  #autoreleted .owl-item:not(:first-child) .item {
    margin-left: 14px;
  }
  #autoreleted .owl-item:not(:last-child) .item {
    margin-right: 14px;
  }
}

@media screen and (max-width: 865px) {
  #autoreleted .owl-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#autoreleted .item {
  padding-bottom: 10px;
}

#autoreleted .item .image {
  min-height: 150px;
}

#autoreleted .item .oct-related-picture {
  display: block;
}

#autoreleted .item .oct-related-picture img {
  width: 100%;
  max-width: 150px;
}

#autoreleted .item .name {
  margin-top: 20px;
  padding-bottom: 2px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

#autoreleted .item .name a {
  transition: 250ms;
  height: auto !important;
  min-height: 56px;
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1.5;
}

@media screen and (max-width: 865px) {
  #autoreleted .item .name a {
    text-align: center;
    justify-content: center;
  }
}

#autoreleted .item .price {
  margin-top: 10px;
  text-align: left;
  letter-spacing: .03em;
  transition: 240ms, transform 500ms;
}

@media screen and (max-width: 865px) {
  #autoreleted .item .price {
    text-align: center;
    margin-bottom: 0;
  }
}

#autoreleted .item .cart {
  display: flex;
  justify-content: center;
  margin-left: -2px;
  margin-right: -2px;
}

#autoreleted .item .cart > * {
  margin-left: 2px;
  margin-right: 2px;
  padding: 0;
  height: 36px;
  line-height: 1.1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (min-width: 866px) {
  #autoreleted .item .cart > * {
    opacity: 0;
    transform: translateY(100%);
  }
}

#autoreleted .item .cart > *:first-child {
  flex-grow: .5;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-size: 12px;
  box-shadow: 0 0px 8px -1px rgba(0, 0, 0, 0.15);
}

#autoreleted .item .cart > *:first-child i {
  margin-right: 3px;
}

#autoreleted .item .cart > *:first-child.out-of-stock-button {
  cursor: text !important;
  background-color: grey;
}

#autoreleted .item .cart > *:not(:first-child) {
  width: 36px;
  background-color: transparent;
  color: #4f5f6f !important;
  border: 2px solid #ddd;
  transition: 200ms, transform 300ms, opacity 300ms, box-shadow 50ms;
  border-radius: 4px;
}

#autoreleted .item .cart > *:not(:first-child):hover {
  background-color: #4f5f6f !important;
  color: #fff !important;
  border-color: #4f5f6f;
}

#autoreleted .item .cart > *:not(:first-child) i {
  font-size: 18px;
}

#autoreleted .item .cart > *:nth-child(1) {
  transition: 200ms, transform 500ms, opacity 300ms, box-shadow 300ms 400ms;
}

#autoreleted .item .cart > *:nth-child(2) {
  transition: 200ms, transform 450ms 50ms, opacity 250ms 50ms;
}

#autoreleted .item .cart > *:nth-child(3) {
  transition: 200ms, transform 450ms 100ms, opacity 250ms 100ms;
}

@media screen and (min-width: 866px) {
  #autoreleted .item:hover .price {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
  }
  #autoreleted .item:hover .cart > * {
    transform: translateY(-100%);
    opacity: 1;
  }
  #autoreleted .item:hover .cart > *:first-child {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  }
}

#autoreleted .owl-prev,
#autoreleted .owl-next {
  position: absolute;
  top: 60px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#autoreleted .owl-prev {
  left: auto;
  right: 100%;
}

@media screen and (max-width: 1685px) {
  #autoreleted .owl-prev {
    transform: translateX(100%);
  }
}

@media screen and (max-width: 740px) {
  #autoreleted .owl-prev {
    transform: translateX(100%) translateX(-20px);
  }
}

#autoreleted .owl-next {
  left: 100%;
  right: auto;
}

@media screen and (max-width: 1685px) {
  #autoreleted .owl-next {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 740px) {
  #autoreleted .owl-next {
    transform: translateX(-100%) translateX(20px);
  }
}

.item.item-box .cart,
.product-thumb .cat-box-effect-inner .cart {
  display: flex;
  justify-content: center;
}

.item.item-box .cart > *,
.product-thumb .cat-box-effect-inner .cart > * {
  margin-left: 2px;
  margin-right: 2px;
  padding: 0 !important;
  height: 36px;
  line-height: 1.1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 36px;
}

.item.item-box .cart > *:first-child,
.product-thumb .cat-box-effect-inner .cart > *:first-child {
  flex-grow: .5;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-size: 12px;
  box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.15);
}

.item.item-box .cart > *:first-child i,
.product-thumb .cat-box-effect-inner .cart > *:first-child i {
  margin-left: 3px;
  margin-right: 3px;
}

.item.item-box .cart > *:first-child.out-of-stock-button,
.product-thumb .cat-box-effect-inner .cart > *:first-child.out-of-stock-button {
  cursor: text !important;
  background-color: grey !important;
}

.item.item-box .cart > *:not(:first-child),
.product-thumb .cat-box-effect-inner .cart > *:not(:first-child) {
  width: 36px;
  background-color: transparent !important;
  color: #264955 !important;
  border: 2px solid #ddd;
  transition: 200ms, transform 300ms, opacity 300ms, box-shadow 50ms;
  border-radius: 4px;
}

.item.item-box .cart > *:not(:first-child):hover,
.product-thumb .cat-box-effect-inner .cart > *:not(:first-child):hover {
  background-color: #264955 !important;
  color: #fff !important;
  border-color: #264955 !important;
}

.item.item-box .cart > *:not(:first-child) i,
.product-thumb .cat-box-effect-inner .cart > *:not(:first-child) i {
  font-size: 18px;
}

.item.item-box .cart .wishlist i::before,
.product-thumb .cat-box-effect-inner .cart .wishlist i::before {
  content: "";
}

@media screen and (min-width: 993px) {
  .is_flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .is_flex::before, .is_flex::after {
    display: none;
  }
}
